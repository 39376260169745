<template>
  <v-card elevation="0">
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>
        {{ $t("order IP/subnet") }}
      </v-toolbar-title>
    </v-toolbar>
    <validation-observer ref="obs" v-slot="{ errors, invalid }">
      <v-card outlined class="mb-4">
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <validation-provider
                vid="oto_id"
                :name="$t('link identifier / OTO ID')"
                rules="required"
                v-slot="{ errors, valid, dirty, classes }"
              >
                <v-text-field
                  v-model="oto"
                  name="oto_id"
                  :label="$t('link identifier / OTO ID')"
                  :error-messages="errors"
                  :success="dirty && valid"
                  :class="classes"
                  :clearable="!isMobile"
                />
              </validation-provider>
            </v-col>
            <v-col cols="6">
              <validation-provider
                vid="prefix_length"
                :name="$t('prefix length')"
                rules="numeric|min_value:27|max_value:30"
                v-slot="{ errors, valid, dirty, classes }"
              >
                <v-select
                  v-model="prefixLength"
                  :items="availablePrefixLengths"
                  name="prefix_length"
                  :label="$t('prefix length')"
                  :error-messages="errors"
                  :success="dirty && valid"
                  :class="classes"
                  type="number"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip>
                      {{
                        $t(
                          item.count == 1 ? "prefix-single" : "prefix-subnet",
                          { value: item.value, count: item.count }
                        )
                      }}
                    </v-chip>
                  </template>
                  <template v-slot:item="{ item, index }">
                    <v-chip>
                      {{
                        $t(
                          item.count == 1 ? "prefix-single" : "prefix-subnet",
                          { value: item.value, count: item.count }
                        )
                      }}
                    </v-chip>
                  </template>
                </v-select>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" v-if="prefixLength != null">
              <validation-provider
                vid="customer.ripeNetname"
                :name="$t('RIPE netname')"
                rules="required|max:50|alpha_dash"
                v-slot="{ errors, valid, dirty, classes }"
              >
                <v-text-field
                  v-model="ripeNetname"
                  name="customer.ripeNetname"
                  :label="$t('RIPE netname')"
                  :hint="$t('network name for RIPE inetnum object')"
                  :error-messages="errors"
                  :success="dirty && valid"
                  :clearable="!isMobile"
                  :class="classes"
                  persistent-hint
                />
              </validation-provider>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card-actions class="mt-2">
        <v-spacer />
        <v-btn :disabled="loading" @click="$emit('canceled')" text>
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          :disabled="loading || invalid || $store.getters.isReadOnly"
          :loading="loading"
          @click="assignNew"
          text
        >
          {{ $t("create") }}
        </v-btn>
      </v-card-actions>
    </validation-observer>
  </v-card>
</template>

<script>
import SwissAddressAutocomplete from "@/components/basics/SwissAddressAutocomplete";
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";

export default {
  name: "IpAddressesTenantsCreate",
  mixins: [showErrors, isMobile],
  components: {
    SwissAddressAutocomplete,
  },
  data() {
    return {
      loading: false,
      oto: null,
      prefixLength: null,
      firstName: null,
      lastName: null,
      company: null,
      address: {
        street: null,
        street_number: null,
        zip: null,
        city: null,
      },
      phone: null,
      email: null,
      ripeNetname: null,
      availablePrefixLengths: [
        { text: "single-ip", value: null, count: 1 },
        { text: "ip-subnet", value: 30, count: 4 },
        { text: "ip-subnet", value: 29, count: 8 },
        { text: "ip-subnet", value: 28, count: 16 },
        { text: "ip-subnet", value: 27, count: 32 },
      ],
    };
  },
  computed: {
    validCustomer() {
      return Boolean(
        (this.company || (this.firstName && this.lastName)) &&
          this.address.city &&
          this.address.zip &&
          this.address.street &&
          this.address.street_number &&
          this.phone &&
          this.email
      );
    },
  },
  methods: {
    assignNew() {
      var that = this;
      this.loading = true;
      this.$http
        .post("services/ip-addresses/tenants", {
          link_identifier: this.oto,
          prefix_length: this.prefixLength,
          ripe_netname: this.ripeNetname || null,
        })
        .then((response) => {
          that.$snotify.success(that.$i18n.t("orderNewIpsSuccessMsg"));
          that.$emit("created");
        })
        .catch((error) => {
          if (error.invalid()) {
            that.$refs.obs.setErrors(error.data);
            that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "order IP/subnet": "Order IP/subnet",
    "ticket ID": "Ticket ID",
    "prefix length": "Prefix length",
    "link identifier / OTO ID": "Link identifier / OTO ID",
    "create": "Create",
    "prefix-single": "/32 - {count} IP address",
    "prefix-subnet": "/{value} - {count} IP addresses",
    "RIPE netname": "RIPE netname",
    "network name for RIPE inetnum object": "network name for RIPE inetnum object (e.g. company name)",
    "orderNewIpsSuccessMsg": "order new IP/subnet successfully done"
  },
  "de": {
    "order IP/subnet": "IP/Subnetz bestellen",
    "ticket ID": "Ticket ID",
    "prefix length": "Prefix Länge",
    "link identifier / OTO ID": "Link Identifikator / OTO ID",
    "create": "Anlegen",
    "prefix-single": "/32 - {count} IP Adressen",
    "prefix-subnet": "/{value} - {count} IP Adressen",
    "RIPE netname": "RIPE Netzname",
    "network name for RIPE inetnum object": "Netzwerk Name für RIPE inetnum Objekt (z.B. Firmenname)",
    "orderNewIpsSuccessMsg": "Bestellung wurde erfolgreich abgeschlossen"
  }
}
</i18n>
