<template>
  <v-card flat>
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>
        {{ item.link_identifier }}
      </v-toolbar-title>
    </v-toolbar>
    <validation-observer ref="obs" v-slot="{ errors, invalid, validated }">
      <v-card-text>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="items"
          item-key="network"
          :mobile-breakpoint="mbreakpoint"
          :hide-default-footer="true"
          show-select
          single-select
        >
          <template v-slot:item.network="{ item }">
            {{ item.network }}
          </template>

          <template v-slot:item.status="{ item }">
            {{ item.status ? item.status : "-" }}
          </template>

          <template v-slot:item.mac="{ item }">
            {{ item.mac ? item.mac : "-" }}
          </template>

          <template v-slot:item.created="{ item }">
            {{ item.created ? formatDateTime(item.created) : "-" }}
          </template>
        </v-data-table>
        <v-row class="mt-4">
          <v-col cols="12">
            <date-picker
              v-model="terminationDate"
              name="termination_date"
              :label="$t('termination date')"
              :clearable="!isMobile"
              today
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn :disabled="loading" @click="$emit('close')" text>
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          :disabled="invalid || loading || selected.length == 0"
          :loading="loading"
          @click="deprovision"
          text
        >
          {{ $t("deprovision") }}
        </v-btn>
      </v-card-actions>
    </validation-observer>
  </v-card>
</template>

<script>
import DatePicker from "@/components/basics/DatePicker";
import formatDateTime from "@/utils/mixins/formatDateTime";
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";

export default {
  name: "IpAddressesTenantsDetails",
  mixins: [formatDateTime, showErrors, isMobile],
  components: {
    DatePicker,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    selected: [],
    loading: false,
    terminationDate: null,
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$i18n.t("IP/subnet"),
          value: "network",
          sortable: false,
        },
        { text: this.$i18n.t("MAC"), value: "mac", sortable: false },
        { text: this.$i18n.t("status"), value: "status", sortable: false },
        { text: this.$i18n.t("created"), value: "created", sortable: false },
      ];
    },
    items() {
      var that = this;
      var items = this.item.networks.map(function (network) {
        return {
          ...network,
          isIp: false,
          isSelectable: (that.item.mac || "") == "",
        };
      });
      items.unshift({
        destination: this.item.ip,
        prefix: this.item.version == 4 ? 32 : 128,
        network: this.item.ip,
        isIp: true,
        isSelectable: items.length == 0 && (this.item.mac || "") == "",
        mac: this.item.mac,
      });
      return items;
    },
    terminationDateTime() {
      if (this.terminationDate) {
        const now = this.$moment();
        if (this.$moment(this.terminationDate).isSame(now, "day")) return null;
        else {
          return this.$moment(this.terminationDate)
            .set({
              hour: 0,
              minute: 0,
              second: 0,
            })
            .toISOString();
        }
      } else return null;
    },
  },
  methods: {
    deprovision() {
      if (this.selected.length > 0) {
        var that = this;
        var selected = this.selected[0];
        that.loading = true;
        this.$http
          .delete(
            "services/ip-addresses/tenants/" + this.item.link_identifier,
            {
              data: {
                ip: selected.isIp ? selected.network : null,
                network: selected.isIp ? null : selected.network,
                termination_date: this.terminationDateTime,
              },
            }
          )
          .then((response) => {
            that.$snotify.success(that.$i18n.t("deprovisionSuccessMsg"));
            that.$emit("deprovisioned");
          })
          .catch((error) => {
            if (error.invalid()) {
              that.$refs.obs.setErrors(error.data);
              that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
            }
          })
          .finally(function () {
            that.loading = false;
          });
      }
    },
  },
};
</script>

<i18n>
{
  "en": {
    "IP/subnet": "IP/Subnet",
    "MAC": "MAC",
    "status": "Status",
    "created": "Created",
    "cancel": "Cancel",
    "deprovision": "Terminate",
    "termination date": "Termination date",
    "ticket ID": "Ticket ID",
    "deprovisionSuccessMsg": "termination successfully done"
  },
  "de": {
    "IP/subnet": "IP/Subnetz",
    "MAC": "MAC",
    "status": "Status",
    "created": "Angelegt",
    "cancel": "Abbrechen",
    "deprovision": "Kündigen",
    "termination date": "Kündigungsdatum",
    "ticket ID": "Ticket ID",
    "deprovisionSuccessMsg": "Kündigung wurde erfolgreich abgeschlossen"
  }
}
</i18n>
